import React from 'react'

import Layout from '../components/layout'

export default () => (
  <Layout>
    <h1>Chi sono e cosa faccio nella vita</h1>

    <p>
      Mi chiamo Carlo Straccialini, ho 34 anni, quasi 35, vivo a Milano e sono felicemente sposato con Adele.
      Sono da sempre appassionato e studioso di informatica e da quasi 9 anni questa mia passione si è trasformata nel mio lavoro.
    </p>

    <p>
      I primi otto anni della mia carriera lavorativa li ho trascorsi nella fantastica azienda <a href="https://www.webratio.com" rel="noreferrer" target="_blank">Webratio</a> dove ho messo a disposizione
      le mie capacità e conoscenze acquisite durante gli studi univerisitari qui al Politecnico di Milano
      per gli sviluppi di applicazioni web e mobile di livello enterprise attraverso la loro piattaforma di sviluppo low-code.
      I vari progetti sui quali sono intervenuto mi hanno permesso di acquisire una conoscenza a 360 gradi
      del ciclo di vita dello sviluppo software, partendo dall'analisi e raccolta di requisiti fino al rilascio
      in produzione e la successiva fase di maintenance e arricchimento con nuove feature.
    </p>

    <p>
      È in questo periodo che comprendo come sia necessario specializzarsi in un ambito preciso
      e tutte le mie attenzioni sono rivolte al mondo front-end in primis e mobile subito un gradino sotto.
      Aiutato anche dai miei responsabili, inizio ad approfondire il mondo Javascript, la libreria jQuery e il framework AngularJS
    </p>

    <p>
      Durante gli ultimi due anni di permanenza in Webratio, sono stato selezionato per la realizzazione di un nuovo prodotto:
      una piattaforma di servizi per il mondo IoT, inizialmente chiamate Semioty ma poi ribrandizzata in <a href="https://www.servitly.com/" rel="noreferrer" target="_blank">Servitly</a>. 
      Il mio principale compito è stato lo sviluppo dell'interfaccia UI
      con l'ausilio del framework Angular, dalla versione beta della 2 in poi.
    </p>

    <p>
      Arrivato a questo punto, sento la necessità di intraprendere nuove esperienze lavorative e mi trasferisco in una società di consulenza:
      vado a ricoprire il ruolo di Frontend Architect nel gruppo IT di una nota banca italiana.
      In questa nuova posizione più che sviluppare direttamente, mi occupo di fornire gli strumenti di lavoro più adatti ad un team di sviluppatori,
      a definire le soluzioni architetturali più performanti per i portali utenti e clienti, ad introdurre le nuove tecnologie in un contesto legacy.
    </p>

    <p>
      L'esperienza acquisita nel periodo di consulenza in una realtà bancaria mi danno molta visibilità nel mondo fintech
      e dopo solo un anno e mezzo mi portano a ricoprire lo stesso ruolo presso un'altra banca italiana dove tutt'ora sono di servizio.
      Le mansioni sono le stesse, negli ultimi mesi si è aggiunta la necessità di sviluppare un nuovo widget container per rimpiazzare
      un prodotto ormai obsoleto e costoso.
    </p>

    <p>
      Accanto al mio attuale ruolo di Frontend Architect, nel tempo libero ho ripreso lo studio dello sviluppo mobile e con l'uscita nel mercato
      di Flutter lo studio e la realizzazione di app cross-platform è notevolmente facilitato. Attraverso questo strumento, sto sviluppando insieme
      ad un amico un'app per il mondo della ristorazione che presto vedrà la luce: di più al momento non posso dire, solo che vogliamo portare o aumentare
      la digitalizzaione nella ristorazione.
    </p>
  </Layout>
)
